import LazyLoad from 'react-lazyload';
import { ReactComponent as ChatBubblesIcon } from '../../assets/icons/chatBubbles.svg';
import { IDefaultSubmissionTag, IIdea } from '../../util/types';
import { formatDate, transformImage, truncate } from '../../util/helpers';
import ProfileAvatar from '../ProfileAvatar';
import Badge from '../Badge';
import styles from './index.module.scss';

interface IProps {
  idea: IIdea;
}

const IdeaCard = ({ idea }: IProps) => {
  const hasBadges = idea.selected || idea.pitched;

  const authorFullName = [idea.author.givenName, idea.author.familyName].join(' ');
  const imageUrl = transformImage({ imageUrl: idea.image?.url });

  const isIdeaSpotlight = idea.tags.includes(IDefaultSubmissionTag.Spotlight);
  const isMoonChallenge = idea.tags.includes(IDefaultSubmissionTag.MoonOriginal);

  return (
    <div className={styles.wrapper} data-cy="idea-card">
      <div className={styles.imageAndTagsContainer}>
        {isIdeaSpotlight && <Badge className={styles.spotlightTag} text="Idea Spotlight" variant="purple" />}
        {isMoonChallenge && <Badge className={styles.moonChallengeTag} text="Moon Original" variant="black" />}
        <LazyLoad height="16.25rem">
          <img className={styles.image} src={imageUrl} alt={`image of ${idea.name}`} />
        </LazyLoad>
      </div>
      <div className={styles.content}>
        <div className={styles.badgesAndComments}>
          {hasBadges && (
            <div className={styles.badges}>
              {idea.pitched && <Badge text="Pitched" variant="green" />}
              {idea.selected && <Badge text="Selected" />}
            </div>
          )}
          <div className={styles.comments}>
            <ChatBubblesIcon className={styles.chatBubblesIcon} />
            <span className={styles.commentCount} data-cy="idea-card-comment-count">
              {idea.commentCount}
            </span>
          </div>
        </div>
        <div className={styles.titleAndDescription}>
          <div className={styles.title}>{idea.name}</div>
          {idea.description && <div className={styles.description}>{truncate(idea.description, 150)}</div>}
        </div>
        <div className={styles.profile}>
          <ProfileAvatar avatarUrl={idea.author?.avatar?.url} firstName={idea.author?.givenName} />
          <div className={styles.profileContent}>
            <div className={styles.profileName}>{authorFullName}</div>
            {idea.publishedAt && <div className={styles.publishedAt}>{formatDate(idea.publishedAt)}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdeaCard;
